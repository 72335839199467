/******************************************************************

1030px and up

******************************************************************/

body {
	font-size: 1.4em;
}

.main-container {
	margin-top: 65px; /* space for the header */
}

.sol-info {
	text-align: right;
}

a.nav-link,
a.nav-link:visited {
	font-size: 0.8em;
}
