/******************************************************************

481px and up

******************************************************************/

/*********************
POSTS & CONTENT STYLES
*********************/

