/******************************************************************

Main

******************************************************************/

header {
	background-color: $header-color;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10000;
}

.bg-gray {
	background-color: #323232;
}

.navbar {
	margin-bottom: 0;

	a {
		cursor: pointer;
	}
}
.navbar-brand {
	padding: 3px 0;
}
.main-logo {
	height: 36px;
	margin: 0 6px;
}

.sol-week {
	color: $text-color;
}

.sol-day-item {
	padding: 16px 10px 0;
}

.sol-desc {
	font-size: 0.8em;
	color: $light-gray;
}

.sol-disclaimers {
	font-size: 0.7em;
	margin-bottom: 1em;
}

.sol-select-form{
	width: 100%;
}

.navbar,
.navbar-nav,
.navbar-dark {

	.nav-item.active {
		color: $yellow !important;
		& > .nav-link {
			color: $yellow;
			&:visited,
			&:hover,
			&:focus,
			&.active {
				color: $yellow;
			}
		}
	}

	a.nav-link,
	a.nav-link:visited {
		text-align: center;
		color: $light-gray;
		font-size: 1em;

		&:hover,
		&:focus,
		&.active {
			color: $yellow;
		}

		&.sol-exit{
			display: flex;
		}
	}
	a.nav-link.tab,
	a.nav-link.tab:visited {
		color: #999;
		font-size: 0.8em;

		&:hover,
		&:focus,
		&.active {
			color: #333;
		}
	}
	.dropdown-menu {
		a.nav-link,
		a.nav-link:visited {
			color: $text-color;

			&:hover,
			&:focus,
			&.active {
				color: $black;
			}
		}
	}
}

table > thead > tr > th {
	background-color: $light-gray;
	color: $dark-gray;
	vertical-align: middle !important;

	.dropdown-toggle.th-filter {
		background: none;
		text-transform: uppercase;
		font-size: 1em;
		color: $dark-gray;
		font-weight: 700;
	}
}

.highlighted {
	background-color: $light-blue;
}
.highlighted2 {
	background-color: $light-green;
}

.btn-default:hover,
.sol-export:hover{
	color: black;
}

.sol-schedule {
	font-size: 1em;

	h4 {
		font-size: 1.6em;
		color: $text-color;
	}

	&.active h4 {
		color: white;
	}
}

.sol-clients {
	font-size: 1em;
	min-width: 250px;
	max-width: 300px;
	height: auto;
}

.sol-statuses {
	font-size: 1em;
	width: auto;
	height: auto;
}

.sol-report-results {
	height: 100%;
	min-height: 300px;
}

.sol-controls{
	margin-bottom: 10px;
}

.qr-code {
	width: 120px;
	height: auto;
}

.scroll-wrapper {
	width: 100%;
	height: 100%;
	overflow-x: scroll;
	min-height: 400px;
}

a.expand-link {
	background-color: rgba(234, 235, 190, 0.459);
	border: 1px solid #BBB;
	width: 100%;
	border-radius: 8px;
	text-align: left;
	font-size: 1em;
	font-weight: bold;
	margin: 0 1em 1em;
	padding: 0 1em;
	cursor: pointer;

	&.collapsed {
		font-weight: normal;
		color: $text-color;
	}
	& > i {
		line-height: 0;
	}
	&:hover {
		color: black;
		font-weight: bold;
		border: 1px solid #000;
	}
}

.map-container-route-service {
	height: 350px;
	border: 1px solid #BBB;
	border-radius: 8px;
	margin: 1em;
}

#trace-map-route{
	width: 100%;
}

#map-container-route {
	height: 350px;
	border: 1px solid #BBB;
	border-radius: 8px;
	margin: 1em;
}

#map-container {
	width: 100%;
	height: 250px;
	border: 1px solid #BBB;
	border-radius: 8px;
	margin: 1em;
}

.list-group-item-light.list-group-item-action.active {
	background-color: rgb(251, 255, 218);
	color: #000;
}

.request-list {
	height: 100%;
	min-height: 50vh;
	overflow-y: scroll;
}

.sol-list,
.schedule-list,
.driver-list {
	padding: 1em;
}

.client-details,
.service-details {
	border: 1px dashed #999;
	margin: 1em 0;
	padding: 1em;
}

.modified,
.modified h4 {
	color: black;
}

.report-btn {
	&:hover {
		color: #007bff;
	}
}

.btn-icon{
	display: inline-flex;
	font-size: 0.9em;
}

.sol-report{
	height: 100%;
	position: absolute;
}

.list-group-item-light.list-group-item-action.log-booking{
	color: #5dade2;
}

.list-group-item-light.list-group-item-action.log-driver{
	color:  #bb8800;
}

.list-group-item-light.list-group-item-action.log-login{
	color:  #229954;
}

.list-group-item-light.list-group-item-action.log-route_service{
	color:   #2211a1;
}

.list-group-item-light.list-group-item-action.log-tracking{
	color:  #666;
}

.list-group-item .log-error{
	color:  #CC0000;
}
