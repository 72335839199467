  /******************************************************************

Alerts

Based on: Bones - github.com/eddiemachado/bones

******************************************************************/

// alerts and notices

/*.alert-danger {
	font-size: 1.5em;
}*/

.dialog__container {
	position: absolute;
	background-color:rgba(0, 0, 0, 0.1);
	top: 0;
	width: 100%;

	.ngdialog-close {
	    border-radius: 5px;
	    cursor: pointer;

	    &:active:before, &:hover:before {
			color:#777
		}

	    &:before {
		    background: 0 0;
		    border-radius: 3px;
		    color: #bbb;
		    content: '\00D7';
		    font-size: 26px;
		    font-weight: 400;
		    height: 30px;
		    line-height: 26px;
		    position: absolute;
		    right: 3px;
		    text-align: center;
		    top: 3px;
		    width: 30px;
		}
	}
}

.dialog__empty-container {
	background-color:rgba(0, 0, 0, 0.1);
	
	.ngdialog-overlay{
		display: none;
		visibility: hidden;
	}

	.ngdialog-content {
		background: transparent;
		margin: 30% auto;
		
		.ngdialog-close {
			display: none;
			visibility: hidden;
		}
	}
}

.loader-container {
	position: fixed;
	top: 66px;
	width: 100%;
	height: 100%;
	padding: 15%;
	background-color: rgba(194, 194, 194, 0.372);
	z-index: 10001;
}

.load-fading-circle {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
  }
  
  .load-fading-circle .load-circle {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
  }
  
  .load-fading-circle .load-circle:before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: $spinner-color;
	border-radius: 100%;
	-webkit-animation: load-circleFadeDelay 1.2s infinite ease-in-out both;
			animation: load-circleFadeDelay 1.2s infinite ease-in-out both;
  }
  .load-fading-circle .load-circle2 {
	-webkit-transform: rotate(30deg);
		-ms-transform: rotate(30deg);
			transform: rotate(30deg);
  }
  .load-fading-circle .load-circle3 {
	-webkit-transform: rotate(60deg);
		-ms-transform: rotate(60deg);
			transform: rotate(60deg);
  }
  .load-fading-circle .load-circle4 {
	-webkit-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
			transform: rotate(90deg);
  }
  .load-fading-circle .load-circle5 {
	-webkit-transform: rotate(120deg);
		-ms-transform: rotate(120deg);
			transform: rotate(120deg);
  }
  .load-fading-circle .load-circle6 {
	-webkit-transform: rotate(150deg);
		-ms-transform: rotate(150deg);
			transform: rotate(150deg);
  }
  .load-fading-circle .load-circle7 {
	-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
			transform: rotate(180deg);
  }
  .load-fading-circle .load-circle8 {
	-webkit-transform: rotate(210deg);
		-ms-transform: rotate(210deg);
			transform: rotate(210deg);
  }
  .load-fading-circle .load-circle9 {
	-webkit-transform: rotate(240deg);
		-ms-transform: rotate(240deg);
			transform: rotate(240deg);
  }
  .load-fading-circle .load-circle10 {
	-webkit-transform: rotate(270deg);
		-ms-transform: rotate(270deg);
			transform: rotate(270deg);
  }
  .load-fading-circle .load-circle11 {
	-webkit-transform: rotate(300deg);
		-ms-transform: rotate(300deg);
			transform: rotate(300deg); 
  }
  .load-fading-circle .load-circle12 {
	-webkit-transform: rotate(330deg);
		-ms-transform: rotate(330deg);
			transform: rotate(330deg); 
  }
  .load-fading-circle .load-circle2:before {
	-webkit-animation-delay: -1.1s;
			animation-delay: -1.1s; 
  }
  .load-fading-circle .load-circle3:before {
	-webkit-animation-delay: -1s;
			animation-delay: -1s; 
  }
  .load-fading-circle .load-circle4:before {
	-webkit-animation-delay: -0.9s;
			animation-delay: -0.9s; 
  }
  .load-fading-circle .load-circle5:before {
	-webkit-animation-delay: -0.8s;
			animation-delay: -0.8s; 
  }
  .load-fading-circle .load-circle6:before {
	-webkit-animation-delay: -0.7s;
			animation-delay: -0.7s; 
  }
  .load-fading-circle .load-circle7:before {
	-webkit-animation-delay: -0.6s;
			animation-delay: -0.6s; 
  }
  .load-fading-circle .load-circle8:before {
	-webkit-animation-delay: -0.5s;
			animation-delay: -0.5s; 
  }
  .load-fading-circle .load-circle9:before {
	-webkit-animation-delay: -0.4s;
			animation-delay: -0.4s;
  }
  .load-fading-circle .load-circle10:before {
	-webkit-animation-delay: -0.3s;
			animation-delay: -0.3s;
  }
  .load-fading-circle .load-circle11:before {
	-webkit-animation-delay: -0.2s;
			animation-delay: -0.2s;
  }
  .load-fading-circle .load-circle12:before {
	-webkit-animation-delay: -0.1s;
			animation-delay: -0.1s;
  }
  
  @-webkit-keyframes load-circleFadeDelay {
	0%, 39%, 100% { opacity: 0; }
	40% { opacity: 1; }
  }
  
  @keyframes load-circleFadeDelay {
	0%, 39%, 100% { opacity: 0; }
	40% { opacity: 1; } 
  }
