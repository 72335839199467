/******************************************************************

Variables

******************************************************************/

$black:             #231f20;
$white:             #fff;
$yellow:			#FFFF55;
$light-yellow:		#fcfce6;

$light-gray:        #EEEEEE;
$gray:              #999999;
$dark-gray:         #404347;
$text-color:        #666666;
$light-blue:        rgb(223, 238, 250);
$light-green:       rgb(223, 250, 224);

$link-color:        $black;
$link-hover:        darken($link-color, 9%);

$border-color:      #ccc;
$disabled-gray:     #cfcfcf;
$alert-red:			red;
$alert-yellow: rgb(205, 164, 0);
$alert-green:		#645564;
$alert-blue:		blue;

$bold:				700;

$header-color:      rgb(50, 50, 50);
$footer-height:		55px;
$spinner-color:     rgb(205, 164, 0);
