/******************************************************************

Typography

******************************************************************/

// Web fonts definitions goes here as well


/* ===============================
     fonts
   =============================== */
$font: "Open Sans", Helvetica, Arial, sans-serif;
$font-italic:  Cambria, Times New Roman, Times, serif;
$font-bold: Helvetica, Arial, sans-serif;
$font-semibold: Helvetica, Arial, sans-serif;
$font-extrabold: Helvetica, Arial, sans-serif;
$font-extralightitalic: Helvetica, Arial, sans-serif;

/* ===============================
     sizes
   =============================== */
$large: 28px;
$medium: 20px;
$small: 14px;

/* ===============================
     weights
   =============================== */
$thin: 300;
$normal: 400;
$semi-bold: 600;
$bold: 700;
$extra-bold: 900;

/*
some nice typographical defaults
more here: http://www.newnet-soft.com/blog/csstypography
*/
p {
  -ms-word-wrap: break-word;
  word-break: break-word; //non-standart css for webkit
  word-wrap: break-word;
  // cleaning up the ragged lines and breaks
  -webkit-hyphens: auto;
     -moz-hyphens: auto;
          hyphens: auto;
  // sets a minimum number of characters before and after the break
  -webkit-hyphenate-before: 2;
   -webkit-hyphenate-after: 3;
           hyphenate-lines: 3;
  // enabling fancy ligatures when available
  -webkit-font-feature-settings: "liga", "dlig";
   -moz-font-feature-settings: "liga=1, dlig=1";
      -ms-font-feature-settings: "liga", "dlig";
       -o-font-feature-settings: "liga", "dlig";
          font-feature-settings: "liga", "dlig";
}

