/******************************************************************

1240px and up

******************************************************************/

.detail-container {
    float: right;

    &.ui-scrollpoint {
        position: fixed;
        top: 50px;
        right: 0;
    }
}