/******************************************************************

Mixins

Based on: Bones - github.com/eddiemachado/bones

******************************************************************/

/*
USAGE: @include transition(all 0.2s ease-in-out);
*/

@mixin transition($transition...) {
  // defining prefixes so we can use them in mixins below
  $prefixes:      ("-webkit-", "" );
  @each $prefix in $prefixes {
    #{$prefix}transition: $transition;
  }
}

/*********************
CSS3 GRADIENTS
*********************/

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
	background-color: $to;
	background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
	background-image: -webkit-linear-gradient(top, $from, $to);
	background-image: -moz-linear-gradient(top, $from, $to);
	background-image: -o-linear-gradient(top, $from, $to);
	background-image: linear-gradient(to bottom, $from, $to);
}

/*********************
BOX SIZING
*********************/

/* @include box-sizing(border-box); */
@mixin box-sizing($type: border-box) {
	-webkit-box-sizing: $type;
	-moz-box-sizing:    $type;
	-ms-box-sizing:     $type;
	box-sizing:         $type;
}


