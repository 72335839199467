/******************************************************************

Mobile

******************************************************************/

/*********************
GENERAL STYLES
*********************/

body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	background-color: $white;
	color: $text-color;
	font-family: $font;
	font-size: 100%;
	line-height: 1.5;
}

html {
	height: 100%;
	box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

ul.info {
	list-style-type: circle;
	margin: 0;
	padding: 15px;

	li {
		font-size: 1.5em;
	}
}

/*********************
LAYOUT & GRID STYLES
*********************/

.main-container {
	margin-top: 60px; /* space for the header */
}

.wrap {
	width: 96%;
	margin: 0 auto;
}

/*********************
LINK STYLES
*********************/

a, a:visited {
	color: $link-color;
	text-decoration: none;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
		text-decoration: underline;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
	text-rendering: optimizelegibility;
	font-weight: 500;

	a {
		text-decoration: none;
	}
}

h1, .h1 {
	font-size: 1.4em;
	line-height: 1em;
	margin-top: 0;
}

h2, .h2 {
	font-size: 1.2em;
	line-height: 1em;
	margin-bottom: 0.375em;
	font-weight: bold;
}

h3, .h3 {
	font-size: 1em;
}

h4, .h4 {
	font-size: 0.875rem;
	font-weight: 700;
}

/*********************
HEADER STYLES
*********************/

.header {
	background-color: $white;
}

/*********************
MAIN PAGE
*********************/
.sol-day {
	font-size: 1em;
	color: $black;
}

.day-check {
	float: right;
}

.sol-info {
	font-size: 0.7em;
	color: $text-color;
	text-align: left;
}

/*********************
TABLES
*********************/

table {
	th {
		text-transform: uppercase;
		font-size: 0.8em;
		text-align: center;
	}
	td {
		text-align: center;
		font-size: 0.8em;
	}
}

/*********************
TOOLBAR STYLES
*********************/
.toolbar {
	position: fixed;
	width: 100%;
	height: 55px;
	bottom: 0;
	top: auto;
	margin: 0;
	padding: 0.4em;
	background-color: #EEE;
	z-index: 9999;

	p {
		text-align: center;
	}
}

/*********************
FOOTER STYLES
*********************/
main-footer {
	width: 100%;
}

footer {
	p {
		text-align: center;
	}
}

.material-icons.time-icon{
	font-size: 18px;
}
