/******************************************************************

Buttons

******************************************************************/

button {

	.toolbar & {
		min-width: 140px;
	}
	
}

.btn-primary,
.btn-success,
.btn-danger {
	
	& > i{
		font-size: 1.2em;
		margin-top: 0;
	}
	
	&:visited,
	&:visited:link {
		color: $white;
	}

	&:hover,
	&:focus,
	&:visited:hover,
	&:visited:focus,
	&:hover:link,
	&:focus:link {
		color: $white;
	}
}

.btn {
	min-width: 55px;
}

.material-icon-positioning{
	position: relative;
    top: 5px;
    margin-top: -5px;
}