/******************************************************************

768px and up

******************************************************************/

/*********************
GENERAL STYLES
*********************/

body {
	font-family: $font;
}

.main-container {
	margin-top: 64px; /* space for the header */
}

.day-check {
	float: none;
}

/*********************
LAYOUT & GRID STYLES
*********************/

